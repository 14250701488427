var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "announcements", staticStyle: { "align-items": "center" } },
    [
      _c("div", { staticClass: "announcements-list" }, [
        _c("div", { staticClass: "announcements-list-announcement" }, [
          _vm._v(" " + _vm._s(_vm.mostRecentQuestionnaire.portalMessage) + " "),
        ]),
      ]),
      !_vm.mostRecentQuestionnaire.isStarted
        ? _c(
            "router-link",
            {
              staticClass: "announcements-close",
              staticStyle: { width: "250px", color: "white" },
              attrs: {
                to: {
                  name: "annual-questionnaire-client",
                  params: { questionnaire: _vm.mostRecentQuestionnaire },
                },
              },
            },
            [
              _vm._v(" Go to Questionnaire "),
              _c("span", [
                _c("i", {
                  staticClass: "fa fa-play",
                  staticStyle: { color: "white" },
                }),
              ]),
            ]
          )
        : _c(
            "router-link",
            {
              staticClass: "announcements-close",
              staticStyle: { width: "250px", color: "white" },
              attrs: {
                to: {
                  name: "annual-questionnaire-client",
                  params: { questionnaire: _vm.mostRecentQuestionnaire },
                },
              },
            },
            [
              _vm._v(" Finish Annual BOLI Questionnaire "),
              _c("span", [
                _c("i", {
                  staticClass: "fa fa-play",
                  staticStyle: { color: "white" },
                }),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }