<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div v-if="isLoading">
      <Loading />
    </div>
    <div
      v-else
      class="flex-container-row"
      id="body-content-area-all"
    >
      <AnnualQuestionnaireAlert v-if="questionnaires.length > 0" :questionnaires="questionnaires" />
      <div v-if="notifications && notifications.length > 0 && !isNotificationsClosed" class="announcements">
        <div class="announcements-title">
          Notice:
        </div>
        <div class="announcements-list">
          <div class="announcements-list-announcement" v-for="notification in notifications" :key="notification.id">
           <div>•</div><div> {{notification.message}}</div>
          </div>
        </div>
        <div class="announcements-close" @click.prevent="closeNotifications">
          <span><i class="fa fa-times"></i></span>
        </div>
      </div>

      <div id="delete-modal" class="modal is-active" style="z-index: 1200;"  v-if="showRequestModel">
          <div class="modal-background" @click="showRequestModel = false"></div>
          <div class="modal-card">
          <header class="modal-card-head has-bg-info">
              <div class="modal-card-title has-bg-info">Confirm Add {{ boliOrColiText }} Request</div>
              <a class="delete" aria-label="close" @click="showRequestModel = false"></a>
          </header>
          <section class="modal-card-body">
              <div class="content">
                Send a request to add {{boliOrColiText}} to your account? You will be contacted by a representative to discuss and coordinate the request.
              </div>
          </section>
          <footer class="modal-card-foot">
              <a class="button" @click="showRequestModel = false">No</a>
              <a class="button is-info" @click="sendRequest" :disabled="isLoadingBoliRequest">
                Yes
                <spin-loader v-if="isLoadingBoliRequest" style="margin-left: .75rem;"/>
              </a>
          </footer>
          </div>
      </div>

      <div id="delete-modal" class="modal is-active"  style="z-index: 1200;" v-if="showBoliChartModal && canShowCharts">
          <div class="modal-background" @click="showBoliChartModal = false"></div>
          <div class="modal-card" style="height: 75vh; width: 50vw;">
          <header class="modal-card-head">
              <div class="modal-card-title">
                {{boliOrColiText}} Assets & Capacity <small>(In ${{chartdata.divisor > 1000 ? '100,000' : '1,000' }}'s)</small>
              </div>
              <a class="delete" aria-label="close" @click="showBoliChartModal = false"></a>
          </header>
          <section class="modal-card-body" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; height: 40vh; width: 50vw; overflow: hidden;">
              <div class="card-body" style="height: 85%; width: 90%; margin: 0 auto;">
                  <div class="title-group">
                    <label class="title" for="" style="margin: 0px;">
                      {{boliOrColiText}} % of Allowable Capital <b>{{chartdata.percentage}}%</b>
                    </label>
                    <label class="title" for="" v-if="activeSite.id === 1">
                      {{boliOrColiText}} Additional Capacity <b>${{(0 > (chartdata.totalAvailable / chartdata.divisor)) ? '0.00' : formatMoney((chartdata.totalAvailable / chartdata.divisor).toFixed(0), false)}}</b>
                    </label>
                  </div>
                  <div class="chart-container" style="max-height: calc(100% - 50px); max-width: calc(100% - 50px); aspect-ratio: 1;">
                    <DoughnutChart
                    :chartdata="chartdata"
                    :options="options"
                    :divisor="chartdata.divisor"
                    :isExpanded="showBoliChartModal"
                    style="width: 100%; height: 100%;"
                  />
                  </div>
                  <legend ref="legend">
                    <div class="group">
                      <div class="boli" id="boli key"></div>
                      <label for="boli key">{{boliOrColiText}}</label>
                    </div>
                    <div class="group">
                      <div class="boli-add-capacity" id="boli add capacity key"></div>
                      <label for="boli add capacity key">{{boliOrColiText}} Add Capacity</label>
                    </div>
                  </legend>
                  <label class="foot-note">
                    As of {{chartdata.callReportDate}}
                  </label>
                </div>
          </section>
          </div>
      </div>

      <div id="delete-modal" class="modal is-active" style="z-index: 1200;" v-if="showAssetSummary">
          <div class="modal-background" @click="showAssetSummary = false"></div>
          <div class="modal-card" style="height: 75vh; min-width: 50vw; max-width: 90vw; width: 100%;">
          <header class="modal-card-head">
              <div class="modal-card-title">Asset Summary  <small>(In ${{chartdata.divisor > 1000 ? '100,000' : '1,000' }}'s)</small></div>
              <a class="delete" aria-label="close" @click="showAssetSummary = false"></a>
          </header>
          <section class="modal-card-body" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; height: 40vh; width: 100%; overflow: hidden; overflow: scroll;">
              <div class="card-body" style="height: 85%; width: 90%; margin: 0 auto;">
                <div v-show="dashboardData.length > 0" class="asset-summary">
                  <table class="table asset-summary" style="width: 100%">
                    <thead>
                      <tr>
                        <th>Carrier</th>
                        <th style="text-align: right;">%</th>
                        <th style="text-align: right;">{{ findStartDate() }}</th>
                        <th style="text-align: right;">{{ findEndDate() }}</th>
                        <th style="text-align: right;">Current Yield</th>
                        <th style="text-align: right!important;">{{findTaxRate() }}% Tax <br> Equivalent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="totals" style="">
                        <td>Totals</td>
                        <td style="text-align: right;">100.00%</td>
                        <td style="text-align: right;">${{ formatMoney((getTotals(dashboardData, 'beginningCashValue', chartdata.divisor).toFixed(0)), false) }}</td>
                        <td style="text-align: right;">${{ formatMoney((getTotals(dashboardData, 'endingCashValue', chartdata.divisor).toFixed(0)), false) }}</td>
                        <td style="text-align: right;">{{((getTotals(dashboardData, 'sumCurrentYield') * 100) / dashboardData.length).toFixed(2)}}%</td>
                        <td style="text-align: right;">{{((getTotals(dashboardData, 'sumCurrentYieldTaxEquivalent') * 100) / dashboardData.length).toFixed(2)}}%</td>

                      </tr>
                      <tr v-for="asset in dashboardData" :key="asset.carrierCode">
                        <td style="text-align: left;">
                          <abbr
                            :title="asset.shortName"
                            style="cursor: pointer;"
                            >{{ asset.carrierCode }} - {{ asset.shortName }}</abbr
                          >
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.percentOfTotal * 100).toFixed(2) }}%
                        </td>
                        <td style="text-align: right;">
                          ${{ formatMoney((asset.beginningCashValue / chartdata.divisor).toFixed(0), false) }}
                        </td>
                        <td style="text-align: right;">
                          ${{ formatMoney((asset.endingCashValue / chartdata.divisor).toFixed(0), false) }}
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.currentYield * 100).toFixed(2) }}%
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.currentYieldTaxEquivalent * 100).toFixed(2) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-show="dashboardData.length === 0">
                    <div
                      v-show="dashboardData.length < 1"
                      style="padding-top: 50px;"
                      class="is-size-6 has-text-centered has-text-weight-bold"
                    >
                      No Asset Summary Data For {{ institution.name }}.
                    </div>
                </div>
              </div>
          </section>
          </div>
      </div>

      <div id="delete-modal" class="modal is-active" style="z-index: 1200;"  v-if="showCreditChartModal" >
          <div class="modal-background" @click="showCreditChartModal = false; getCreditChartData(true, () => $forceUpdate())"></div>
          <div class="modal-card" style="height: 75vh; width: 90vw;">
          <header class="modal-card-head">
            <div class="modal-card-title">
               Credit Concentration <small>(In ${{chartdata.divisor > 1000 ? '100,000' : '1,000' }}'s)</small>
            </div>
            <a class="delete" aria-label="close" @click="showCreditChartModal = false; getCreditChartData(true, () => $forceUpdate())"></a>
          </header>
          <section class="modal-card-body" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; padding: 0px!important; height: 50vh; width: 100%;">
              <credit-concentration-chart
                  v-if="showCreditChart"
                  :chartdata="creditchartdata"
                  :options="creditoptions"
                  :showCode="false"
                  class="chart-container"
                  style="padding: 5rem 0 0 0px!important; height: 100%; max-height: 100%;"
                />
                <Loading v-else/>
          </section>
          </div>
      </div>

      <div v-if="canShowCharts" :class="['flex-container', (showBoliChart || showCreditChart) && canShowCharts ? 'flex-1/3' : '']">
        <div class="card" v-if="showBoliChart" style="max-height: 50%; height: 100%;">
          <div class="has-text-centered heading">
            <h1 class="is-size-6" style="padding: 10px 0px; position: relative;">
              {{boliOrColiText}} Assets & Capacity <small>(In ${{chartdata.divisor > 1000 ? '100,000' : '1,000' }}'s)</small>
              <div class="expandable-chart" @click="showBoliChartModal = true">
                <i class="fa fa-expand"></i>
              </div>
            </h1>
          </div>
          <div class="card-body" >
            <div class="card-chart" style="height: 100%; width: 100%;">
              <div class="title-group">
              <label class="title" for="" style="margin: 0px;">
                {{boliOrColiText}} % of {{activeSite.id === 1 ? 'Allowable Capital' : 'Net Worth'}} <b>{{formatMoney(chartdata.percentage, false)}}%</b>
              </label>
              <label class="title" for="" v-if="activeSite.id === 1">
                {{boliOrColiText}} Additional Capacity <b>${{(0 > (chartdata.totalAvailable / chartdata.divisor)) ? '0.00' : formatMoney((chartdata.totalAvailable / chartdata.divisor).toFixed(0), false)}}</b>
              </label>
            </div>
            <div ref="doughnutChartContainer" @click="showBoliChartModal = true" style="position: relative; display: inline-block; height: calc(100% - 50px); width: 100%;">
              <DoughnutChart
                :chartdata="chartdata"
                :options="options"
                :divisor="chartdata.divisor"
                :site="activeSite.id"
                class="chart-container"
                :style="{
                  height: '100%',
                  display: 'inline-block',
                  marginBottom: '1rem',
                  padding: 0
                }"
              />
            </div>
            <div style="position: absolute; right: 0rem; bottom: 0; padding: .6rem 1rem; color: #8e8e8e;">
                <button @click="showRequestModel = true" class="button is-accent has-text-white add-boli-button" ref="addBOLIButton" type="submsit" >
                  Add {{boliOrColiText}}
                </button>
              </div>
            <legend style="position: absolute; width: 70%; left: 0; right: 0; margin: 0 auto; bottom: 0;padding: .35rem 1rem; color: #8e8e8e;" ref="legend">
              <div class="group" style="padding: .25rem;">
                <div class="boli" id="boli key"></div>
                <label for="boli key">{{boliOrColiText}}</label>
              </div>
              <div class="group"  style="padding: .25rem;">
                <div class="boli-add-capacity" id="boli add capacity key"></div>
                <label for="boli add capacity key">{{boliOrColiText}} Add Capacity</label>
              </div>
            </legend>
            <div class="add-boli-container" style="display: none;">
              <button
                class="button is-accent has-text-white"
                ref="addBOLIButton"
                @click.prevent="showRequestModel = true"
                type="submit"
              >
                Add {{boliOrColiText}}
              </button>
            </div>
            <label class="foot-note-tile">
              As of {{chartdata.callReportDate}}
            </label>
            </div>
          </div>
        </div>
        <div class="card" style="max-height: 50%; height: 100%;" v-if="showCreditChart">
          <div class="has-text-centered heading" style="background-color: #ededed;">
            <h1 class="is-size-6" style="padding: 10px 0px; position: relative;">
              Credit Concentration <small>(In ${{chartdata.divisor > 1000 ? '100,000' : '1,000' }}'s)</small>

              <div class="expandable-chart" @click="showCreditChartModal = true; getCreditChartData(false, () => $forceUpdate())">
                <i class="fa fa-expand"></i>
              </div>
            </h1>
          </div>
          <div class="card-body card-chart" @click="showCreditChartModal = true; getCreditChartData(false, () => $forceUpdate())">
            <credit-concentration-chart
              v-if="showCreditChart"
              :chartdata="creditchartdata"
              :options="creditoptions"
              :showCode="true"
              class="chart-container"
            />
          </div>
        </div>
      </div>

      <div class="flex-container flex-2/3" :style="{ margin: (showBoliChart || showCreditChart) && canShowCharts ? '' : '0 auto'}">
        <div class="card" style="max-height: 50%; height: 100%;" v-if="dashboardData.length > 0">
            <div class="has-text-centered heading" style="background-color: #ededed;">
              <h1 class="is-size-6" style="padding: 10px 0px">
                Asset Summary <small>(In ${{(chartdata.totalAmount / 1000) > 100000 ? '100,000' : '1,000' }}'s)</small>
                <div class="expandable-chart" @click="showAssetSummary = true">
                <i class="fa fa-expand"></i>
              </div>
              </h1>
            </div>
            <div class="card-body">
                <div
                  v-show="dashboardData.length < 1"
                  style="padding-top: 50px;"
                  class="is-size-6 has-text-centered has-text-weight-bold"
                >
                  No Asset Summary Data For {{ institution.name }}.
                </div>
                <div v-show="dashboardData.length > 0" class="table-container asset-summary">
                  <table class="table asset-summary" style="width: 100%">
                    <thead>
                      <tr>
                        <th>Carrier</th>
                        <th style="text-align: right;">%</th>
                        <th style="text-align: right;">{{ findStartDate() }}</th>
                        <th style="text-align: right;">{{ findEndDate() }}</th>
                        <th style="text-align: right;">Current Yield</th>
                        <th style="text-align: right!important;">{{findTaxRate() }}% Tax Equivalent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="totals" style="">
                        <td>Totals</td>
                        <td style="text-align: right;">100.00%</td>
                        <td style="text-align: right;">${{ formatMoney((getTotals(dashboardData, 'beginningCashValue', chartdata.divisor).toFixed(0)), false) }}</td>
                        <td style="text-align: right;">${{ formatMoney((getTotals(dashboardData, 'endingCashValue', chartdata.divisor).toFixed(0)), false) }}</td>
                        <td style="text-align: right;">{{((getTotals(dashboardData, 'sumCurrentYield') * 100) / dashboardData.length).toFixed(2)}}%</td>
                        <td style="text-align: right;">{{((getTotals(dashboardData, 'sumCurrentYieldTaxEquivalent') * 100) / dashboardData.length).toFixed(2)}}%</td>

                      </tr>
                      <tr v-for="asset in dashboardData" :key="asset.CarrierCode">
                        <td style="text-align: left;">
                          <abbr
                            :title="asset.shortName"
                            style="cursor: pointer;"
                            >{{ asset.carrierCode }}</abbr
                          >
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.percentOfTotal * 100).toFixed(2) }}%
                        </td>
                        <td style="text-align: right;">
                          ${{ formatMoney((asset.beginningCashValue / chartdata.divisor).toFixed(0), false) }}
                        </td>
                        <td style="text-align: right;">
                          ${{ formatMoney((asset.endingCashValue / chartdata.divisor).toFixed(0), false) }}
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.currentYield * 100).toFixed(2) }}%
                        </td>
                        <td style="text-align: right;">
                          {{ (asset.currentYieldTaxEquivalent * 100).toFixed(2) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
        <div class="card"  style="max-height: 50%; height: 100%;">
          <div class="has-text-centered heading" style="background-color: #ededed; margin: 0;">
            <h1 class="is-size-6" style="padding: 10px 0px">
              {{ pageheading.toLocaleUpperCase() }}
            </h1>
          </div>
          <div class="card-body">
              <div
                v-show="reports.length < 1"
                style="padding-top: 50px;"
                class="is-size-6 has-text-centered has-text-weight-bold"
              >
                No Reports recent reports for {{ institution.name }}.
              </div>
              <div v-show="reports.length > 0" class="table-container">
                <table class="table" style="width: 100%">
                  <thead>
                    <tr>
                      <th>Recently Uploaded Documents</th>
                      <th>Report Date</th>
                      <th>Upload Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="report in reports" :key="report.id">
                      <td @click.stop="getReport(report)">
                        <abbr
                          :title="report.displayName"
                          style="cursor: pointer;"
                          >{{ report.displayName }}</abbr
                        >
                      </td>
                      <td v-if="getReportDateFromString(report.displayName) !== ''">
                        {{ getReportDateFromString(report.displayName) }}
                      </td>
                      <td v-else>
                        {{
                          report.uploadDate
                            | moment("timezone", "Europe/Saratov", "MM/DD/YYYY")
                        }}
                      </td>
                      <td>
                        {{
                          report.uploadDate
                            | moment("timezone", "Europe/Saratov", "MM/DD/YYYY")
                        }}
                      </td>
                      <td>
                        <a :href="report.path" download><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Loading from './Loading'
import { activeLayout, activeSite } from '../vuex-actions'
import DoughnutChart from './charts/Doughnut.vue'
import CreditConcentrationChart from './charts/CreditConcentration.vue'
import AnnualQuestionnaireAlert from './annual-questionnaire/ClientSide/AnnualQuestionnaireAlert.vue'
import moment from 'moment-timezone'
import { bus } from '../main'

// TODO: dedupe
function trim (source, c) {
  if (c === ']') c = '\\]'
  if (c === '\\') c = '\\\\'
  return source.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}

let baseUrl = trim(process.env.VUE_APP_API_URL, '/')
baseUrl = trim(baseUrl, 'api')
baseUrl = trim(baseUrl, '/')

export default {
  components: {
    Loading,
    DoughnutChart,
    CreditConcentrationChart,
    AnnualQuestionnaireAlert
    // MonthlyReport: async () => import('./MonthlyReport.vue')
  },
  data () {
    return {
      windowSize: {
        height: 0,
        width: 0
      },
      questionnaires: [],
      canShowCharts: false,
      showAssetSummary: false,
      dashboardData: [],
      reportTypeCodes: [],
      showBoliChartModal: false,
      showCreditChartModal: false,
      reports: [],
      errorsList: [],
      notifications: [],
      isNotificationsClosed: false,
      showRequestModel: false,
      pdfSource: null,
      reportTypes: new Map(),
      isLoading: true,
      isLoadingPreview: true,
      isLoadingBoliRequest: false,
      sectionstates: [],
      institution: {
        id: 0,
        name: ''
      },
      showBoliChart: false,
      showCreditChart: false,
      pageheading: 'Recent Reports',
      monthlyReport: {
        page: null,
        report: null,
        pages: 1,
        show: false
      },
      isIE: !!window.MSInputMethodContext && !!document.documentMode,
      chartdata: {
        percentage: 0,
        datasets: [
          {
            labels: ['$9,425', '$23,077'],
            borderColor: ['#3e61a6', '#7387c0'],
            backgroundColor: ['#3e61a6', '#7387c0'],
            data: [-9425, -23077]
          }
        ],
        callReportDate: ''
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              return ctx.dataset.labels[ctx.dataIndex]
            },
            font: {
              size: '16'
            },
            color: '#fff',
            backgroundColor: 'transparent'
          }
        }
      },
      creditchartdata: {
        labels: [],
        datasets: [
          {
            formatted: ['$6,923', '$3,462', '$2,308', '$1154', '$6,925'],
            data: [6923, 3462, 2308, 1154, 6925],
            backgroundColor: '#2c7abb'
          },
          {
            formatted: ['$19,501', '$19,501', '$19,501', '$19,501', '$19,501'],
            data: [19501 - 6923, 19501 - 3462, 19501 - 2308, 19501 - 1154, 19501 - 6925],
            backgroundColor: '#8cb554'
          }
        ]
      },
      creditoptions: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        labels: {
          enabled: false
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              return ctx.dataset.formatted[ctx.dataIndex]
            },
            font: function (context) {
              let width = window.innerWidth
              let size = 0

              if (width >= 2400) {
                size = 16
              } else if (width < 2400 && width >= 1500) {
                size = 14
              } else if (width < 1500 && width >= 1280) {
                size = 12
              } else {
                size = 10
              }

              return {
                size: size
              }
            },
            offset: function (context) {
              return -19
            },
            color: function (context) {
              let data = (context.dataset.formatted[context.dataIndex].replace(/[^0-9.]/g, ''))
              let scales = context.chart.scales['y-axis-0']
              var index = context.dataset.backgroundColor

              return index === '#8cb554' ? '#656565' : ((scales.options.ticks.suggestedMax - 200) <= data ? '#656565' : 'white')
            },
            backgroundColor: 'transparent',
            align: 'start',
            anchor: 'end'
          }
        },
        bounds: 'data',
        scales: {
          x: {
            ticks: {
              autoSkip: false
            }
          },
          y: {
            type: 'linear',
            grace: '10%',
            grid: {
              display: true,
              drawBorder: true,
              color: 'grey',
              drawOnChartArea: true,
              drawTicks: true
            },
            max: 0
          },
          xAxes: [
            {
              barPercentage: 1.25,
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              ticks: {
                // stepSize: 1000,
                suggestedMax: 0,
                // padding: 100,
                display: true,
                beginAtZero: true,
                weight: 1,
                userCallback: function (value, index, values) {
                  value = value.toString()
                  value = value.split(/(?=(?:...)*$)/)
                  value = value.join(',')
                  return value
                }
              }
            }
          ]
        }
      }
    }
  },
  async created () {
    this.GetInstitution()
    this.getNotifications()
    this.getChartData()
    this.getMembershipsData()
    this.getDashboardData()
  },
  computed: {
    ...mapState(['clientSession', activeSite]),
    ...mapGetters([activeLayout]),
    maxHeightForDoughnutChart () {
      if (this.windowSize.width >= 1921) {
        return this.activeLayout === 'osr-facing' ? '375px' : '400px'
      } else if (this.windowSize.width < 1921 && this.windowSize.width < 750) {
        return this.activeLayout === 'osr-facing' ? '240px' : '250px'
      } else {
        return this.activeLayout === 'osr-facing' ? '225px' : '250px'
      }
    },
    pageTitle () {
      if (this.institution) {
        return (
          'Dashboard' +
          ' - ' +
          this.institution.name +
          ' ' +
          (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal')
        )
      }

      return 'Dashboard - '
    },
    boliOrColiText () {
      return this.activeSite.id === 1 ? 'BOLI' : 'COLI'
    }
  },
  watch: {
    showBoliChartModal (state) {
      if (state) {
        this.options.plugins.datalabels.font.size = 16
      } else {
        this.options.plugins.datalabels.font.size = this.activeLayout === 'osr-facing' ? 6 : 8
      }
    }
  },
  mounted () {
    let notification = this.$cookies.get('isNotificationsClosed')
    this.isNotificationsClosed = notification !== null

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.getCurrentQuestionnaireForInstitution()

    window.requestAnimationFrame(_ => bus.$emit('full-screen-toggle', false))
  },
  methods: {
    async getCurrentQuestionnaireForInstitution () {
      this.api().annualQuestionnaire.getCurrentQuestionnaireForInstitution({ institutionId: this.clientSession.institutionId }, (_error, _response) => {
        if (_error) {
          // this.errorsList.push(_error)
        } else {
          this.questionnaires = _response.map(q => {
            let slide = window.localStorage.getItem(`{${q.questionnaireId}}annual-questionnaire-slide`)
            let hasStarted = slide !== null && slide !== undefined
            return {
              ...q,
              isStarted: hasStarted
            }
          })
        }
      })
    },
    async getDashboardData () {
      this.api().charts().getAssetSummary(this.clientSession.institutionId, (_response, _error) => {
        this.dashboardData = _response !== null ? _response : []
      })
    },
    findTaxRate () {
      let avg = 0
      let count = 0

      this.dashboardData.map(record => {
        avg += record.taxRate
        count += 1
      })

      return (avg / count).toFixed(2)
    },
    findEndDate () {
      let avg = ''

      this.dashboardData.map(record => {
        avg = record.monthEndDate
        return null
      })

      return moment(avg).utc().format('MM/DD/YYYY')
    },

    findStartDate () {
      let avg = ''

      this.dashboardData.map(record => {
        avg = record.monthBeginDate
        return null
      })

      return moment(avg).utc().format('MM/DD/YYYY')
    },
    handleResize () {
      this.$nextTick(() => {
        this.canShowCharts = false

        let func = () => {
          this.canShowCharts = true
        }

        this.windowSize = {
          width: window.innerWidth,
          height: window.innerHeight
        }

        // Make to take a minute so that we can force re-render of the charts
        setTimeout(func.bind(this), 50)
      })
    },
    async getMembershipsData () {
      this.api().getMembershipsData(this.clientSession.userId, (_response, _error) => {
        if (_error) {
          this.canShowCharts = false
        } else {
          if (_response.reportTypeCodes.includes('MBR') || _response.reportTypeCodes.includes('MCR')) {
            this.canShowCharts = true
          } else {
            this.canShowCharts = false
          }
        }
      })
    },
    async getChartData (shouldGroup = true, completion = () => {}) {
      this.api().charts().boli(this.clientSession.institutionId, (_response, _error) => {
        if (_error) {
          this.showBoliChart = false
        } else {
          let totalAmount = parseInt(_response.entityOwnedLifeInsuranceCapacity)
          let totalUsed = parseInt(_response.entityOwnedLifeInsuranceUsed)
          let totalAvailable = parseInt(_response.entityOwnedLifeInsuranceAvailable)
          this.chartdata.callReportDate = _response.callReportDate
          let divisor = 1

          let dataSet = [
            {
              labels: [`$${this.formatMoney(totalUsed.toFixed(0), false)}`, `$${this.formatMoney(totalAvailable.toFixed(0), false)}`],
              borderColor: ['#3e61a6', '#7387c0'],
              backgroundColor: ['#3e61a6', '#7387c0'],
              data: [totalUsed * -1, totalAvailable * -1]
            }
          ]
          this.chartdata.totalAmount = totalAmount
          this.chartdata.totalUsed = totalUsed
          this.chartdata.totalAvailable = totalAvailable.toFixed(0)
          this.chartdata.divisor = divisor

          this.chartdata.datasets = dataSet
          this.chartdata.percentage = (this.chartdata.totalAmount - this.chartdata.totalUsed) < 1 ? '100' : ((this.chartdata.totalUsed / (this.chartdata.totalAmount * 4)) * 100).toFixed(2)

          if (this.chartdata.percentage === '100') {
            this.chartdata.totalAvailable = totalAvailable
            this.chartdata.datasets = [{
              labels: ['', `$${this.formatMoney(totalUsed.toFixed(0), false)}`, `$${this.formatMoney(totalAmount.toFixed(0), false)}`],
              borderColor: ['#3e61a6', '#7387c0'],
              backgroundColor: ['#3e61a6', '#7387c0'],
              data: [0, 100]
            }]
          }

          this.showBoliChart = true
        }
      })

      this.getCreditChartData(shouldGroup, completion)
    },
    getCreditChartData (shouldGroup = true, completion = () => {}) {
      this.showCreditChart = false

      this.api().charts().credit(this.clientSession.institutionId, (_response, _error) => {
        if (!_error) {
          this.creditchartdata.labels = []
          this.creditchartdata.data = []
          let max = 0

          let dataSet1 = {
            formatted: [],
            data: [],
            backgroundColor: '#2c7abb'
          }
          let dataSet2 = {
            formatted: [],
            data: [],
            backgroundColor: '#8cb554',
            grandTotal: 0
          }

          _response.filter(_carrier => {
            this.creditchartdata.labels.push({
              code: _carrier.carrierCode,
              name: _carrier.carrier
            })

            let divisor = ((_carrier.grandTotal / 1000) > 100000 ? 100000 : 1000)
            dataSet2.divisor = divisor

            // dataSet1.grandTotal = this.formatMoney((_carrier.carrierTotal / 1000).toFixed(0), false)
            dataSet2.grandTotal = this.formatMoney((_carrier.grandTotal / divisor).toFixed(0), false)

            dataSet1.formatted.push(this.formatMoney((_carrier.carrierTotal / divisor).toFixed(0), false))
            dataSet1.data.push(_carrier.carrierTotal / divisor)
            dataSet2.formatted.push(this.formatMoney((_carrier.grandTotal / divisor).toFixed(0), false))
            dataSet2.data.push(((Number(_carrier.grandTotal) - Number(_carrier.carrierTotal)) / divisor))

            if (max < (_carrier.grandTotal / divisor) + 200) {
              max = (_carrier.grandTotal / divisor) + 200
            }
          })

          this.creditoptions.totalAmount = max
          this.creditoptions.scales.yAxes[0].ticks.suggestedMax = Math.round(max) + 200

          if (dataSet1.data.length > 5 && shouldGroup) {
            let datasets = {
              dataSet1: { ...dataSet1 },
              dataSet2: { ...dataSet2 }
            }

            dataSet1.data = dataSet1.data.slice(0, 4)
            dataSet1.formatted = dataSet1.formatted.slice(0, 4)

            dataSet2.data = dataSet2.data.slice(0, 4)
            dataSet2.formatted = dataSet2.formatted.slice(0, 4)

            let dataSet1Max = 0
            let dataSet2Max = 0
            let grandTotal = parseInt(dataSet2.grandTotal.replaceAll(',', ''))

            datasets.dataSet1.data.slice(4, datasets.dataSet1.data.length + 1).map(dataItem => (dataSet1Max += dataItem))
            datasets.dataSet2.data.slice(4, datasets.dataSet2.data.length + 1).map(dataItem => (dataSet2Max += dataItem))

            dataSet1.data.push(dataSet1Max)
            dataSet2.data.push(grandTotal - dataSet1Max)

            dataSet1.formatted.push(this.formatMoney(dataSet1Max.toFixed(0)))
            dataSet2.formatted.push(dataSet2.grandTotal)

            // Adjust the labels
            this.creditchartdata.labels = this.creditchartdata.labels.slice(0, 4)
            this.creditchartdata.labels.push({ code: 'Other', name: 'Other' })
          }

          this.$nextTick(() => {
            this.creditoptions.timestamp = moment().valueOf()
            this.creditchartdata.datasets = [dataSet1, dataSet2]
            this.showCreditChart = true
            completion()
          })
        }
      })
    },
    closeNotifications () {
      this.isNotificationsClosed = true
      this.$cookies.set('isNotificationsClosed', true)
    },
    async getNotifications () {
      let that = this
      this.api().getActiveAnnouncements((_response, _error) => {
        if (_error) {
          that.errorsList.push(_error)
        }

        if (_response) {
          that.notifications = _response
        }
      })
    },
    async sendRequest () {
      try {
        this.isLoadingBoliRequest = true
        const response = await this.axios.post('/documents/clients/notify-staff')

        if (response.status >= 200 && response.status < 300) {
          this.successToast('Successfully sent request')
        } else {
          this.errorToast('Something went wrong')
        }
      } catch (exception) {
        this.errorToast('Unable to send request')
      } finally {
        this.showRequestModel = false
        this.isLoadingBoliRequest = false
      }
    },
    getReport (report) {
      this.$router.push({
        name: 'ViewReport',
        params: { id: report.id, report: report }
      })
    },
    isLeapYear (year) {
      if (year % 400) {
        return true
      } else if (year % 100) {
        return false
      } else if (year % 4) {
        return true
      } else {
        return false
      }
    },
    getUploadDateFormated (reportDate) {
      const date = new Date(reportDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1 // Because it is zero based
      let day = date.getUTCDay()

      if (day === 0) {
        day++
      }

      if (month === 0) {
        month++
      }

      if (day.toString().length === 1) {
        day = `0${day}`
      }

      if (month.toString().length === 1) {
        month = `0${month}`
      }

      return `${month}/${day}/${year}`
    },
    getReportDateFromString (reportName) {
      let dateString = ''
      const year = parseInt(reportName.substring(0, 4))

      // See if we have a valid year from the string.
      if (year && year > 2000) {
        let month = '-1'

        if (reportName.substring(5, 7).includes('Q')) {
          switch (parseInt(reportName.substring(6, 7))) {
            case 1:
              month = '03'
              break
            case 2:
              month = '06'
              break
            case 3:
              month = '09'
              break
            case 4:
              month = '12'
              break
            default:
              month = ''
              break
          }
        }

        if (month === '-1') {
          month = reportName.substring(5, 7)
        }

        // Make sure we have a valid month
        if (month && parseInt(month) > 0 && parseInt(month) <= 12) {
          let day = ''

          switch (parseInt(month)) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
              day = '31'
              break
            case 2:
              day = this.isLeapYear(year) ? '29' : '28'
              break
            case 4:
            case 6:
            case 9:
            case 11:
              day = '30'
              break
            default:
              day = ''
              break
          }

          dateString += `${month}/${day}/${year}`
        } else {
          // If no valid month then just pass in the year value
          dateString += `${year}`
        }
      }

      return dateString
    },
    getReportPath (path) {
      // TODO: this will be removed once report.path has full https://* path
      path = trim(path, '/')
      return `${baseUrl}/${path}`
    },
    async GetInstitution () {
      try {
        let that = this
        this.isLoading = true
        this.axios
          .get(`/institutions/${this.clientSession.institutionId}`)
          .then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              that.institution = response.data
              that.getReports()
            }
          })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    getReports () {
      try {
        let that = this
        this.isLoading = true
        let route = `/reports?perPage=10&sortBy=uploadDate&sortDirection=desc`
        this.axios.get(route, null).then(function (response) {
          if (response.status >= 200 && response.status < 300) {
            that.reports = response.data.records
          }
        })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style scoped>
.table.asset-summary {
  overflow: scroll;
}
.table.asset-summary thead tr {
  background-color: #f7f7f7!important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table.asset-summary tr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.table.asset-summary tr.totals{
  padding: 1rem;
  background-color: #1c4d7c!important;
  color: white;
}

.table.asset-summary tr:last-of-type{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table.table.asset-summary td:first-of-type {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.table.asset-summary td {
  padding: .75rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.table.asset-summary tr:nth-child(2n - 1) {
  background-color: #e5e5e5;
}

.expandable-chart {
  position: absolute;
  left: 0rem;
  top: 0;
  padding: .6rem 1rem;
  color: #8e8e8e;
}

.expandable-chart:hover {
  cursor: pointer;
  color: darkgray;
}
#app {
  background-color: #d9d9d9!important;
}

#pagelayout {
  width: 100%;
}

#body-content-area-all {
    background-color: #d9d9d9!important;
    width: 98%!important;
    overflow-y: scroll;
  }

@media (max-width: 1000px) {
  .container {
    padding: 0rem !important;
  }

  #body-content-area-all {
    padding: 0rem !important;
    background-color: white!important;
  }

  .card {
    border: 1px solid #f2f2f2;
  }
}
.container {
  padding: 0rem 8rem;
}

@media (max-width: 700px) {
  #MyAccount {
    display: none;
  }
}

@media (min-width: 2000px) {
  .pdf {
    width: 44% !important;
  }
}

@media (min-width: 1000px) and (max-width: 1999px) {
  .pdf {
    width: 79% !important;
  }
}

@media (min-width: 701px) and (max-width: 999px) {
  .pdf {
    width: 100% !important;
  }
}

.pdf {
  width: 100%;
  background-color: white;
  border: 2px solid rgb(237, 237, 237);
  border-radius: 0.25rem;
  cursor: pointer;
}

@media (min-width: 801px) {
  .bg-brand-lg {
    background-color: #d9d9d9 !important;
  }
}

legend {
  display: flex;
  justify-content: center;

  margin-top: -1rem;

  font-size: .75rem!important;
}

legend > div.group {
  display: flex;

  padding: 1rem;
}

legend > div.group:nth-of-type(2) {
  padding-left: 3rem;
}

legend > div.group > .boli {
  background-color: #7387c0;

  margin-top: auto;
  margin-bottom: auto;
  width: .75rem;
  height: .75rem;
}

legend > div.group > .boli-add-capacity {
  background-color: #3e61a6;

  margin-top: auto;
  margin-bottom: auto;
  width: .75rem;
  height: .75rem;
}

legend > div.group > label {
  padding-left: 0.25rem;
  /* font-size: 1rem; */
}

.title-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title-group > .title {
  width: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center !important;
  padding-bottom: .2rem;
}
.card-body > .title-group > .title{
  font-size: 1rem;
}

@media (max-width: 450px) {
  .title-group > .title {
    padding-bottom: 0rem;
    margin-top: -3rem;
  }

  legend {
    display: flex;
    justify-content: center;

    margin-top: -2rem;
  }

  legend > div.group {
    display: flex;
    flex-wrap: wrap;
    padding: 0rem;
  }

  legend > div.group:nth-of-type(2) {
    padding-left: 3rem;
  }

  legend > div.group > .boli {
    background-color: #7387c0;

    width: 100%;
    height: 2rem;
  }

  legend > div.group > .boli-add-capacity {
    background-color: #3e61a6;
    padding: .5rem;
    width: 100%;
    height: 2rem;
  }

  legend > div.group > label {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin-top: -1.75rem;
    font-size: 1rem;

    color: white;
  }

  .add-boli-container {
    display: flex;
    justify-content: flex-end;

    padding: 0rem!important;
    padding-top: 1rem!important;
  }
}

@media (min-width: 451px) and (max-width: 800px) {
  .title-group > .title {
    padding-bottom: 0rem;
    margin-top: -1rem;
  }

  .chart-container {
    margin-top: 0rem;
  }

  legend {
    display: flex;
    justify-content: center;

    margin-top: 1rem;
  }

  legend > div.group {
    display: flex;
    flex-wrap: wrap;
    padding: 0rem;
  }

  legend > div.group:nth-of-type(2) {
    padding-left: 3rem;
  }

  legend > div.group > .boli {
    background-color: #7387c0;

    width: 100%;
    height: 2rem;
  }

  legend > div.group > .boli-add-capacity {
    background-color: #3e61a6;
    padding: .5rem;
    width: 100%;
    height: 2rem;
  }

  legend > div.group > label {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin-top: -1.75rem;
    font-size: 1rem;

    color: white;
  }

  .add-boli-container {
    display: flex;
    justify-content: flex-end;

    padding: 0rem!important;
    padding-top: 1rem!important;
  }
}

.chart-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.foot-note{
  position: absolute;
  bottom: 1.6rem;
  left: 2rem;
  font-size: 1rem;
  color: hsl(0, 0%, 21%);
  font-weight: 300;
  line-height: 1.125;
}
.foot-note-tile{
  position: absolute;
  left: 1rem;
  bottom: 0.7rem;
  font-size: 0.7rem;
  color: hsl(0, 0%, 21%);
  font-weight: 300;
  line-height: 1.125;
}
.add-boli-container {
  display: flex;
  justify-content: flex-end;

  padding-top: 0rem;
}

.table-container {
  padding: .5rem;
  padding-bottom: 0rem!important;
  width: 100%;
}

.table-container.asset-summary{
  padding: .5rem;
  padding-bottom: 0rem!important;
  width: 100%;

  scrollbar-width: none;
  overflow-y: scroll!important;
}

.table-container{
  overflow-y: scroll!important;
}

</style>

<style>

@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');

.asset-summary td {
  font-family: 'Roboto', sans-serif;
}

.monthly-report {
  height: calc(100% - 240px)!important;
  width: 100%;

  margin-bottom: 1rem;
}

.add-boli-button {
  padding: .25rem;
  font-size: .85rem;
  margin-top: -.25rem;
}

.flex-container-row {
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;

  max-height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-1\/3 {
  width: 40%;
}

.flex-2\/3 {
  width: 60%;
}

.chart-container {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;

  aspect-ratio: 1;
}

.chart-container  canvas {
  margin-left: auto!important;
  margin-right: auto!important;

  width: 100%!important;
  height: 100%!important;
  max-width: 90%;
  max-height: 90%;

  aspect-ratio: 1!important;
}

th {
  word-break: keep-all!important;
  white-space: nowrap!important;
}

.card {
  background-color: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;

  margin: .5rem;
}

.card .heading {
  margin-bottom: 0px!important;
  background-color: #ededed;
  width: 101%;

  transform: translateX(0px)!important;
  -webkit-transform: translateX(0px)!important;
}

.card .card-body {
  display: flex;
  flex-direction: column;

  align-content: center;
  align-items: center;
  justify-items: center;

  padding: .5rem;

  width: 100%;
  overflow: hidden;
  height: calc(100% - 42.4px);
}

.card .card-body .container {
  display: flex;
  flex-direction: column;

  align-content: center;
  align-items: center;
  justify-items: center;

  padding: 0rem;

  width: 100%;
  overflow: hidden;
  overflow-y: scroll;

  scrollbar-width: 0px;
  max-height: 90%;
  height: 100%;
}

.card .chart-container {
  display: flex;
  flex-direction: row;

  align-content: center;
  align-items: center;
  justify-items: center;

  padding: .5rem;
  width: 100%;
  height: 100%;
}

.card .chart-container canvas {
  width: 100%!important;
  height: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px){
  .flex-1\/3 {
    width: 50%;
    /* flex: 2; */
    order: 1;
  }

  .flex-2\/3 {
    width: 50%;
    /* flex: 1; */
    order: 2;
  }
}

@media screen and (max-width: 1920px) {

  .table-container {
    position: relative!important;
  }
}

@media screen and (max-width: 1024px) {

  .monthly-report {
    height: calc(90%)!important;
    width: 100%;
  }

  .flex-container {
    overflow: auto;
    max-height: auto;
    height: auto;
  }

  .flex-container-row {
    overflow: auto;
    max-height: auto;
    height: auto;
  }

  .card {
    overflow: visible;
    overflow-x: hidden;
    overflow-y: visible;

    height: auto;
  }

  .card .card-body {
    height: auto;
    overflow-y: visible;
  }

  .title-group > .title {
    padding-bottom: 0rem;
    margin-top: 1rem!important;
  }

  .flex-1\/3 {
    width: 100%;
    /* flex: 2; */
    order: 2;
  }

  .flex-2\/3 {
    width: 100%;
    /* flex: 1; */
    order: 1;
  }
}

.announcements {
  background-color: #7fb942;
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: .5rem;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.announcements-title {
  padding: .5rem;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: 10%;
}

.announcements-close {
  padding: .5rem;
  transition: color .5s ease-in-out;
}

.announcements-close span i:hover {
  color: lightgray;
  cursor: pointer;
}

.announcements-list {
  padding: .5rem;
  color: white;
  width: 90%;
}

.announcements-list-announcement {
  display: flex!important;
  flex-direction: row!important;
}

.announcements-list-announcement div:first-of-type {
  content: '• ';
  font-weight: bold;
  line-height: 1rem;
  width: calc(0% + 1rem);
}

.announcements-list-announcement div:last-of-type {
  width: calc(100% - 3rem);
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.card-chart {
  cursor: pointer;
}
</style>

<style scoped>
#body-content-area-all {
  padding: 25px 0px 0px 25px!important;
  width: 100%!important;
}
</style>
